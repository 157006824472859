jQuery(function($) {

  /*
  * FAQ
  */
  $(document).on('click touchend', '.us_faq__title', function(e) {

    e.preventDefault();
    e.stopPropagation();

    var trigger = $(this),
        faq = trigger.parents('.us_faq'),
        offset;

    if ( $(window).width() >= 760 ) {
      if ( $(window).width() >= 960 ) {
        // >= 960px
        offset = 209; // header
      } else {
        // >= 760px
        offset = 206; // header
      }
    } else {
      // < 760px
      offset = 50; // header
    }

    if ( !faq.hasClass('is-active') ) {
      $('.us_faq.is-active').each(function() {
        var self = $(this);
        self.removeClass('is-active');
        self.find('.us_faq__content').slideUp(300);
      });
      faq.addClass('is-active');
      faq.find('.us_faq__content').slideDown(300, function() {
        $('html, body').animate({ scrollTop: ( faq.offset().top - offset ) });
      });
    } else {
      faq.removeClass('is-active');
      faq.find('.us_faq__content').slideUp(300);
    }

  });

  $(document).on('click touchend', '.us_faq-nav__item', function(e) {

    e.preventDefault();
    e.stopPropagation();

    var filter_block = $(this).parents('.us_faq-nav'),
        offset;

    if ( $(window).width() >= 760 ) {
      if ( $(window).width() >= 960 ) {
        // >= 960px
        offset = 209; // header
      } else {
        // >= 760px
        offset = 206; // header
      }
    } else {
      // < 760px
      offset = 50; // header
    }

    $('html, body').animate({ scrollTop: ( filter_block.offset().top - offset ) });

    var category = $(this).data('category');

    $('.us_faq-title').html( $(this).html() );

    $('.us_faq-nav__item.is-active').removeClass('is-active');
    $(this).addClass('is-active');

    if ( category === '*' ) {
      $('.us_faq:not(.is-visible)').show( 0 );
    } else {
      $('.us_faq:not([data-categories*="' + category + '"])').removeClass('is-visible').hide( 0, function() {
        $('.us_faq[data-categories*="' + category + '"]').addClass('is-visible').show( 0 );
      });
    }

  });

});