/*
 * Disable and enable scrolling
 */
import bodyScrollLock from '../../../inc/body-scroll-lock/bodyScrollLock.min';

export function disableScroll( element ) {
  jQuery('body').addClass('block-scroll');
  bodyScrollLock.disableBodyScroll( element[0] );
}

export function enableScroll( element ) {
  jQuery('body').removeClass('block-scroll');
  bodyScrollLock.enableBodyScroll( element[0] );
}

jQuery(function($) {

  /*
   * Resizing
   */
  $(window).resize(function() {
    if ( timer ) {
      clearTimeout( timer );
    }

    var timer = setTimeout(function() {
      $('body').removeClass('is-resizing');
    }, 1000);

    // add resizing class to body element
    $('body').addClass('is-resizing');

    // initialize post slider
    initFeaturedPostSlider();

    // set info box padding
    setInfoboxPadding();
  });



  /*
   * Back to Top
   */
  if ( $('.us_back-to-top').length > 0 ) {
    $(document).on('touchend click', '.us_back-to-top', function(e) {
      e.preventDefault();

      $('html, body').animate({
        'scrollTop' : 0
      }, 300);
    });
  }



  /*
   * Respect fixed header when anchor link
   */
  if ( $('.us_header.is-sticky').length > 0 ) {
    $('a[href*=\\#]:not([href=\\#])').click(function() {

      if ( $('.wc-tabs').has( $(this) ).length ) {
        return;
      }

      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {

        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html,body').animate({
            scrollTop: target.offset().top - ( $('.us_header').height() + 20 ) // offsets for fixed header
          }, 300);
          // return false;
        }
      }
    });
    // Executed on page load with URL containing an anchor tag.
    if ( $(location.href.split("#")[1]) ) {
      var target = $('#'+location.href.split("#")[1]);
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - ( $('.us_header').height() ) // offset height of header here too.
        }, 300);
        // return false;
      }
    }
  }



  /*
   * Info Box Padding
   */
  function setInfoboxPadding() {
    // is header sticky
    if ( $('.us_header').hasClass('is-sticky') ) {
      // has infobox
      if ( $('.us_header__infobox').length > 0 ) {
        // reset padding top on body element
        $('body').css('padding-top', '');

        // get height infobox
        var infobox_height = $('.us_header__infobox').outerHeight();
        
        // set new padding top for body element
        $('body').css('padding-top', '+=' + infobox_height);
      }
    }
  }
  setInfoboxPadding();



  /*
   * Sticky Header
   */
  // Hide Header on on scroll down
  var didScroll;
  var lastScrollTop = 0;
  var delta = 50;
  var navbarHeight = $('.us_header').outerHeight();

  $(window).scroll(function(){
    didScroll = true;

    if ( $(window).scrollTop() > 0 ) {
      $('body').addClass('is-not-top');
    } else {
      $('body').removeClass('is-not-top');
    }

    if ( $(window).scrollTop() > 50 ) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  });

  setInterval(function() {
    if ( didScroll ) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {
    var st = $(this).scrollTop();
      
    // Make sure they scroll more than delta
    if ( Math.abs(lastScrollTop - st ) <= delta ) {
      return;
    }
    
    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if ( st > lastScrollTop && st > navbarHeight ) {
      // Scroll Down
      $('.us_header').removeClass('is-scroll-up').addClass('is-scroll-down');
    } else {
      // Scroll Up
      if ( st + $(window).height() < $(document).height() ) {
        $('.us_header').removeClass('is-scroll-down').addClass('is-scroll-up');
      }
    }
    
    lastScrollTop = st;
  }



  /*
   * Mobile Navigation
   */
  if ( $('.us_header__nav-trigger').length && $('.us_mobile-navigation').length ) {
    $(document).on('touchend click', '.us_header__nav-trigger', function(e) {
      e.preventDefault();

      var trigger      = $(this),
          nav          = $('.us_mobile-navigation'),
          header       = $('.us_header');

      if ( trigger.hasClass('is-active') ) {
        trigger.removeClass('is-active');
        nav.removeClass('is-active');

        setTimeout(function() {
          header.removeClass('is-nav-open');
        }, 1000);

        enableScroll( nav );
      } else {
        trigger.addClass('is-active');
        nav.addClass('is-active');

        header.addClass('is-nav-open');

        disableScroll( nav );
      }
    });

    $(document).on('touchend click', '.us_mobile-navigation li.menu-item-has-children', function(e) {
      if( e.target.nodeName == 'A' ) return;

      e.stopPropagation();
      
      var self = $(this);

      if ( self.hasClass('is-active') ) {
        e.preventDefault();
        self.removeClass('is-active');
      } else {
        e.preventDefault();
        self.addClass('is-active');
      }
    });
  }



  /*
   * Mobile Breadcrumbs
   */
  if ( $('.us_sub-header__breadcrumb').length && $('.us_sub-header__breadcrumb > span').length ) {
    var element = $('.us_sub-header__breadcrumb > span'),
        maxScrollLeft = element.get(0).scrollWidth - element.get(0).clientWidth;

    element.scrollLeft( maxScrollLeft );
  }



  /*
   * Mobile Sub Navigation
   */
  if ( $('.us_sub-header__subnavi').length && $('.us_sub-header__subnavi .is-active').length ) {
    var scrollPosition = 0;
    
    if ( !$('.us_sub-header__subnavi ul').find('.is-active').is(':first-child') ) {
      scrollPosition = $('.us_sub-header__subnavi ul').find('.is-active').position().left;
    }

    $('.us_sub-header__subnavi ul').scrollLeft( scrollPosition );
  }



  /*
   * Mobile Footer Navigation
   */
  if ( $('.us_sub-footer__nav-title').length ) {
    $(document).on('touchend click', '.us_sub-footer__nav-title', function(e) {
      e.preventDefault();

      var self = $(this),
          nav = self.parents('.us_sub-footer__column').find('ul');
      
      if ( nav.hasClass('is-active') ) {
        nav.removeClass('is-active');
      } else {
        $('.us_sub-footer__column ul.is-active').removeClass('is-active');
        nav.addClass('is-active');
      }
    });
  }



  /*
   * Internal Link
   */
  $(document).on('touchend click', '.us_internal', function(e) {
    e.preventDefault();

    if ( $(this).parents('.us_focus-window').length ) {
      var focus_target  = $( $(this).attr('href') ),
          parent_window = $(this).parents('.us_focus-window'),
          title_height  = parent_window.find('.us_title').outerHeight(),
          focus_offset  = focus_target.position().top + title_height + 69;

      $('.us_focus-window').animate({
        'scrollTop' : focus_offset
      }, 300);
    } else {
      var target = $( $(this).attr('href') ),
          offset = target.offset().top;

      $('html, body').animate({
        'scrollTop' : offset - ( $('.us_header').height() + 20 )
      }, 300);
    }
  });


  
  /*
   * Featured Post Slider
   */
  var featuredPostSlider = undefined;

  function initFeaturedPostSlider() {
    if ( $('.us_featured-swiper').length ) {
      var screenWidth = $(window).width();

      if ( screenWidth < 760 && featuredPostSlider == undefined ) {
        featuredPostSlider = new Swiper('.us_featured-swiper.swiper-container', {
          loop: false,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        });
      } else if ( screenWidth >= 760 && featuredPostSlider != undefined ) {
        featuredPostSlider.destroy();
        featuredPostSlider = undefined;

        $('.us_featured-swiper .swiper-slide').removeAttr('style');
      }
    }
  }
  initFeaturedPostSlider();



  /*
   * Searchform
   */
  if ( $('.us_header__icon--search').length > 0 ) {
    $(document).on('touchend click', '.us_header__icon--search', function(e) {
      e.preventDefault();

      var searchform = $('.us_searchform');

      if ( ! searchform.hasClass('is-active') ) {
        // disable scroll
        disableScroll( searchform );

        // add class to searchform
        searchform.addClass('is-active');

        searchform.find('input[type="text"]').focus();
      } else {
        // remove class from searchform
        searchform.removeClass('is-active');

        // enable scroll
        enableScroll( searchform );
      }
    });

    $(document).on('touchend click', '.us_searchform__close', function(e) {
      e.preventDefault();

      // remove class from searchform
      $(this).parents('.us_searchform').removeClass('is-active');

      // enable scroll
      enableScroll( $(this).parents('.us_searchform') );
    });

    // on esc button
    $(document).keyup(function(e) {
      if ( e.keyCode === 27 ) {
        if ( $('.us_searchform').hasClass('is-active') ) {
          // remove class from searchform
          $('.us_searchform').removeClass('is-active');

          // enable scroll
          enableScroll( $('.us_searchform') );
        }
      }
    });

    $(document).on('touchend click', '.us_searchform', function(e) {
      if ( $(this).hasClass('is-active') ) {
        var container = $('.us_searchform.is-active .frame-content');

        // if the target of the click isn't the container nor a descendant of the container
        if ( ! container.is( e.target ) && container.has( e.target ).length === 0 ) {
          // remove class from searchform
          $('.us_searchform').removeClass('is-active');

          // enable scroll
          enableScroll( $('.us_searchform') );
        }
      }
    });

    $('.us_searchform form').submit(function() {
      if ( $(this).find('input[name="s"]').val() !== '' ) {
        $.ajax({
          data: $(this).serialize(),
          type: $(this).attr('method'),
          url: $(this).attr('action'),
          success: function( response ) {
            var input_element = $('.us_searchform input[name="s"]');
            var input_content = input_element[0];
            input_element.after('<div class="fake-input"></div>');

            var replacement_element = $('.us_searchform .fake-input');
            replacement_element.after( input_content );
            
            input_element.val('');
            $('.us_searchform__results').html(response);

            setTimeout(function() {
              replacement_element.remove();
            }, 200);
          }
        });
      }
      return false;
    });
  }



  /*
   * Fancybox
   */
  // Fancybox Image
  if ( $('.wp-block-image').length > 0 ) {
    $().fancybox({
      selector : '.wp-block-image a:visible[href$=".gif"], .wp-block-image a:visible[href$=".jpg"], .wp-block-image a:visible[href$=".jpeg"], .wp-block-image a:visible[href$=".bmp"], .wp-block-image a:visible[href$=".png"]',
      loop : true,
      caption : function() {
        return $(this).parent().find('figcaption').html();
      }
    });
  }

  // Fancybox Gallery
  if ( $('.wp-block-gallery').length > 0 ) {
    $().fancybox({
      selector : '.wp-block-gallery a:visible[href$=".gif"], .wp-block-gallery a:visible[href$=".jpg"], .wp-block-gallery a:visible[href$=".jpeg"], .wp-block-gallery a:visible[href$=".bmp"], .wp-block-gallery a:visible[href$=".png"]',
      loop : true,
      caption : function() {
        return $(this).parent().find('figcaption').html();
      }
    });
  }

});