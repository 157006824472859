jQuery.fn.isOnScreen = function(){
  var win = jQuery(window);

  var viewport = {
    top : win.scrollTop(),
    left : win.scrollLeft()
  };
  viewport.right = viewport.left + win.width();
  viewport.bottom = viewport.top + win.height();

  var bounds = this.offset();
  bounds.right = bounds.left + this.outerWidth();
  bounds.bottom = bounds.top + this.outerHeight();

  return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
};



/*
 * Table of Contents function
 */
export function setActiveToC() {
  if ( jQuery('.us_toc a').length ) {
    jQuery('.us_toc a').each(function() {
      var self = jQuery(this),
          target;

      if ( self.parents('.us_focus-window').length ) {
        var parent_window = self.parents('.us_focus-window');
        target = parent_window.find( self.attr('href') );
        
        if ( target.isOnScreen() ) {
          self.parent().addClass('is-active');
        } else {
          if ( self.parent().hasClass('is-active') ) {
            self.parent().removeClass('is-active');
          }
        }
      } else {
        target = jQuery( self.attr('href') );
        
        if ( target.isOnScreen() ) {
          self.parent().addClass('is-active');
        } else {
          if ( self.parent().hasClass('is-active') ) {
            self.parent().removeClass('is-active');
          }
        }
      }
    });
  }
}

jQuery(function($) {

  /*
   * Table of Contents logic
   */
  // Set active Table of Contents
  setActiveToC();

  $(window).scroll(function(){
    // Set active Table of Contents
    setActiveToC();
  });

});