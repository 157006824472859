import { disableScroll, enableScroll } from './general';
import { setActiveToC } from './table-of-contents';


jQuery(function($) {

  /*
   * Focus Mode
   */
  function openFocusWindow( clickedElement, noHistory ) {

    // set defaults in variables
    var defaultTitle = document.title,
        defaultUrl   = window.location.href;

    // create variable for future usage
    var new_title = '';

    // setup variable for clicked element
    var clickedHref   = clickedElement.attr('href'),
        clickedOffset = clickedElement.offset(),
        clickedTop    = clickedOffset.top - $(document).scrollTop(),
        clickedLeft   = clickedOffset.left - $(document).scrollLeft(),
        clickedWidth  = clickedElement.width(),
        clickedHeight = clickedElement.height();

    if ( !focus_mode_label ) {
      var focus_mode_label = 'Focus Mode';
    }

    // create focus window and save it in a variable in order to manipulate this element later on
    var focus = $('<div class="us_focus-window" style="transform: translate( ' + clickedLeft + 'px, ' + clickedTop + 'px ); width: ' + clickedWidth + 'px; height: ' + clickedHeight + 'px;"><div class="us_focus-window__content" style="opacity: 0;"><span class="us_focus-window__close-top us_focus-window__close-trigger"></span><span class="us_focus-window__close-right us_focus-window__close-trigger"></span><span class="us_focus-window__close-bottom us_focus-window__close-trigger"></span><span class="us_focus-window__close-left us_focus-window__close-trigger"></span><a class="us_focus-window__close us_focus-window__close-trigger">' + focus_mode_label + '</a></div></div>');

    // append focus window to body
    $('body').append( focus );

    // save default page title and url to focus window
    focus.data( 'default-title', defaultTitle );

    if ( !noHistory ) {
      focus.data( 'default-url', defaultUrl );
    } else {
      focus.data( 'default-url', defaultStateUrl );
    }

    // load content from the clicked element and append it to the created focus window
    $.ajax({
      url: clickedHref,
      async: false,
      success: function(data) {
        // append content from the clicked element to the focus window
        focus.find('.us_focus-window__content').append( $(data).filter('.us_page-content') );

        // get new page title
        new_title = $(data).filter('title').text();

        // add event to allow child themes to hook into the focus mode action
        var openEvent = new CustomEvent('onFocusOpen');
        document.dispatchEvent( openEvent );

        // execute function on open
        onOpenFocus();
      }
    });

    // disable the scroll on the page except for in the focus window
    disableScroll( focus );

    // using a setTimeout in order for animations etc. to work
    setTimeout(function() {
      // display the focus window
      focus.addClass('is-active');

      // set current page title to new page title
      document.title = new_title;

      if ( !noHistory ) {
        // change url
        history.pushState( 'focus_open', new_title, clickedHref );
      }
    }, 1);

  }

  function closeFocusWindow( noHistory ) {
    // check if a focus window is visible
    if ( $('.us_focus-window.is-active').length ) {
      // select visible focus window
      var focus = $('.us_focus-window.is-active:last');

      // set current page title to default page title
      document.title = focus.data('default-title');

      if ( !noHistory ) {
        // change url
        history.pushState( 'focus_close', focus.data('default-title'), focus.data('default-url') );
      }

      // remove class from focus window
      focus.removeClass('is-active');

      // enable the scroll again
      enableScroll( focus );

      // add event to allow child themes to hook into the focus mode action
      var closeEvent = new CustomEvent('onFocusClose');
      document.dispatchEvent( closeEvent );

      // remove the focus window to avoid too many elements being build up in the DOM
      setTimeout(function() {
        focus.remove();
      }, 600);
    }
  }

  if ( $('.focus-block:not(.is-link)').length ) {
    var defaultStateUrl = window.location.href;
    history.replaceState( 'focus_default', document.title, defaultStateUrl );

    $(document).on('click', '.focus-block:not(.is-link)', function(e) {
      e.preventDefault();

      if ( ! $(this).attr('href') ) {
        openFocusWindow( $(this).find('a').eq(0) );
      } else {
        openFocusWindow( $(this) );
      }
    });

    $(document).on('touchend click', '.us_focus-window__close-trigger', function(e) {
      e.preventDefault();

      closeFocusWindow();
    });

    // on esc button
    $(document).keyup(function(e) {
      if ( e.keyCode === 27 ) {
        closeFocusWindow();
      }
    });

    $(window).bind('popstate', function (event) {
      if ( event.originalEvent.state === 'focus_default' || event.originalEvent.state === 'focus_open' || event.originalEvent.state === 'focus_close' ) {

        if ( event.originalEvent.state === 'focus_default' ) {
          $('.us_focus-window.is-active').each(function() {
            closeFocusWindow( true );
          });
        }

        if ( event.originalEvent.state === 'focus_open' ) {
          var element = $('.focus-block:not(.is-link)[href="' + window.location.href + '"]');
          openFocusWindow( element, true );
        }

        if ( event.originalEvent.state === 'focus_close' ) {
          closeFocusWindow( true );
        }

      }
    });

  }

  function onOpenFocus() {
    // Load Accordion Blocks
    if ( $('.us_focus-window .js-accordion-item').length > 0 ) {

      $.getScript( ursprung.plugin_dir + 'accordion-blocks/js/accordion-blocks.js' );

      $('.us_focus-window .js-accordion-item').each(function() {
        $(this).accordionBlockItem({
          // Set default settings
          initiallyOpen: $(this).data('initially-open'),
          autoClose:     $(this).data('auto-close'),
          clickToClose:  $(this).data('click-to-close'),
          scroll:        $(this).data('scroll'),
          scrollOffset:  $(this).data('scroll-offset')
        });
      });
      
    }

    setTimeout(function() {
      // Set active Table of Contents
      setActiveToC();
    }, 500);

    $('.us_focus-window').scroll(function(){
      // Set active Table of Contents
      setActiveToC();
    });
  }

});