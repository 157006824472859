jQuery(function($) {

  // Fancybox Gallery
  // if ( $('.woocommerce-product-gallery__image:not(.clone) > a').length > 0 ) {
  //   $().fancybox({
  //     selector : '.woocommerce-product-gallery__image:not(.clone) > a',
  //     loop : true
  //   });
  // }

});